import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useChargeHistoryList() {
  const chargeHistoryListTable = ref([])
  const companyData = ref([])
  const company = ref({})

  const tableColumns = [
    { text: 'First Name', value: 'company_employee.first_name' },
    { text: 'Last Name', value: 'company_employee.last_name' },
    { text: 'Mobile Number', value: 'company_employee.mobile_number' },
    { text: 'Employee Number', value: 'company_employee.employee_number' },
  ]

  const searchQuery = ref('')
  const totalChargeHistoryListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const chargeHistoryTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchSingleCharge = () => {
    store
      .dispatch('app-charge-history/fetchSingleCharge', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        const { filteredData, total, chargeHistoryTotal, company_data } = response.data.data
        const companyInfo = response.data.data.company_data.company
        chargeHistoryListTable.value = filteredData
        totalChargeHistoryListTable.value = total
        chargeHistoryTotalLocal.value = chargeHistoryTotal
        companyData.value = company_data
        company.value = companyInfo

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchSingleCharge()
  })

  return {
    fetchSingleCharge,
    chargeHistoryListTable,
    companyData,
    company,
    tableColumns,
    searchQuery,
    totalChargeHistoryListTable,
    loading,
    options,
    chargeHistoryTotalLocal,
    selectedRows,
  }
}
