import router from '@/router'
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCharges() {
      const { id } = router.currentRoute.query

      return new Promise((resolve, reject) => {
        axios
          .get(`/company-charge-histories?options={"company_id":${id}}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleCharge() {
      const { id } = router.currentRoute.params

      return new Promise((resolve, reject) => {
        axios
          .get(`/employee-charge-histories?options={"charge_id":${id}}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
