import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"charge-history-list"}},[_c(VCard,{attrs:{"item":_vm.companyData}},[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VRow,{staticClass:"px-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('li',[_vm._v(" Invoice ID: "+_vm._s(_vm.companyData.id)+" ")])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('li',[_vm._v(" Charging Date: "+_vm._s(_vm.companyData.date)+" ")])])],1),_c(VRow,{staticClass:"px-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('li',[_vm._v(" Total Number Of Employees: "+_vm._s(_vm.companyData.count_employees)+" ")])]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('li',[_vm._v(" Number Of Points/person: "+_vm._s(_vm.companyData.points)+" ")])])],1),_c(VRow,{staticClass:"px-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('li',[_vm._v(" Delivery Factor: "+_vm._s(_vm.companyData.delivery_factor)+" ")])]),_c(VCol,{attrs:{"cols":"12","sm":"4","item":_vm.company}},[_c('li',[_vm._v(" Address: "),_c('br'),_vm._v(" - Name: "+_vm._s(_vm.company.name)+" "),_c('br'),_vm._v(" - Street Name: "+_vm._s(_vm.company.street_name)+" "),_c('br'),_vm._v(" - Longitude: "+_vm._s(_vm.company.longitude)+" "),_c('br'),_vm._v(" - Latitude: "+_vm._s(_vm.company.latitude)+" "),_c('br'),_vm._v(" - Building Number: "+_vm._s(_vm.company.building_number)+" ")])])],1),_c(VDivider,{staticClass:"mt-4"}),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.chargeHistoryListTable,"options":_vm.options,"server-items-length":_vm.totalChargeHistoryListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-chef-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v("5")])]}},{key:"item.currentPlan",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.currentPlan))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.handleClick(item)}}},'v-btn',attrs,false),on),[_c('router-link',{attrs:{"to":{ name : 'apps-charge-view', params :{id:item.id } }}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiEyeOutline))])],1)],1)]}}],null,true)})]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }